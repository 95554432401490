import { ID } from './common';
import { CompanyConnection } from './company';

export enum ExternalSystemKind {
  ACCOUNTING_SYSTEM = 'AccountingSystem',
  INVENTORY_MANAGEMENT_SYSTEM = 'InventoryManagementSystem',
  PAYMENT_PROVIDER = 'PaymentProvider',
  POINT_OF_SALE = 'PointOfSale',
  SALES_CHANNEL = 'SalesChannel',
  FEED = 'Feed',
}

export const EXTERNAL_SYSTEM_KINDS: ExternalSystemKind[] = [
  ExternalSystemKind.ACCOUNTING_SYSTEM,
  ExternalSystemKind.INVENTORY_MANAGEMENT_SYSTEM,
  ExternalSystemKind.PAYMENT_PROVIDER,
  ExternalSystemKind.POINT_OF_SALE,
  ExternalSystemKind.SALES_CHANNEL,
  ExternalSystemKind.FEED,
];

export interface ExternalSystemTypeKind {
  id: ExternalSystemKind;
  title: string;
  allowsMultipleConnections: boolean;
  displayOrder: number;
  externalSystemTypes: ExternalSystemType[];
}

export type ExternalSystem = AccountingSystem | Feed | SalesChannel | InventoryManagementSystem | PointOfSale | PaymentProvider;

export interface ExternalSystemType {
  id: ExternalSystem;
  typeKindId: ExternalSystemKind;
  title: string;
  description: string | null;
  status: ExternalSystemTypeStatus;
  brokenStatusIsFixable: boolean;
  displayOrder: number;
  features: number;
  priceExclVat: number | null;
}

export enum ExternalSystemTypeStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ExternalSystemTypeFeature {
  NONE = 0,
  API_ENABLED = 1,
  INVOICING_RESPONSIBLE = 2,
  ORDER_DISTRIBUTION = 4,
  PRODUCT_DISTRIBUTION = 8,
  SALES_CHANNEL_BOOKKEEPING = 16,
  PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING = 32,
  TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING = 64,
  PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING = 128,
}

export type ExternalSystemTypeFeatureBillingable =
  ExternalSystemTypeFeature.ORDER_DISTRIBUTION |
  ExternalSystemTypeFeature.PRODUCT_DISTRIBUTION |
  ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING |
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING |
  ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING |
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING;

export type ExternalSystemTypeFeatureBookkepingMode =
  ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING |
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING |
  ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING |
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING;

export const ExternalSystemTypeBookkeepingModeFeatureBits: ExternalSystemTypeFeatureBookkepingMode[] = [
  ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING,
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING,
  ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING,
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING,
];

export const ExternalSystemTypeBookkeepingModeFeature: number = ExternalSystemTypeBookkeepingModeFeatureBits.reduce((accumulator: number, bit) => accumulator | bit, 0);

/* Feature - ExternalSystemTypeFeature or a combination of them
 *
 * Example
 * isExternalSystemTypeSupportsFeature(externalSystemType, ExternalSystemTypeFeature.API_ENABLED)
 * isExternalSystemTypeSupportsFeature(externalSystemType, ExternalSystemTypeFeature.API_ENABLED | ExternalSystemTypeFeature.INVOICING_RESPONSIBLE)
 */
export function isExternalSystemTypeSupportsFeature(externalSystemType: ExternalSystemType, feature: number): boolean {
  return !!(externalSystemType.features & feature);
}

export function isExternalSystemTypeSupportsBookkeepingModes(externalSystemType: ExternalSystemType): boolean {
  return isExternalSystemTypeSupportsFeature(externalSystemType, ExternalSystemTypeBookkeepingModeFeature);
};

export function isExternalSystemTypeSupportsBookkeepingMode(externalSystemType: ExternalSystemType, bookkeepingMode: ExternalSystemTypeFeatureBookkepingMode): boolean {
  return isExternalSystemTypeSupportsFeature(externalSystemType, bookkeepingMode);
};

export function isExternalSystemTypeSupportsOnlyOneAnyBookkeepingMode(externalSystemType: ExternalSystemType): boolean {
  const externalSystemTypeBookkeepingModeFeature: number = externalSystemType.features & ExternalSystemTypeBookkeepingModeFeature;

  return ExternalSystemTypeBookkeepingModeFeatureBits.reduce((accumulator: boolean, bit) => accumulator || !(externalSystemTypeBookkeepingModeFeature & ~bit), false);
};

export function isExternalSystemTypeSupportsOnlyOneExactBookkeepingMode(externalSystemType: ExternalSystemType, bookkeepingMode: ExternalSystemTypeFeatureBookkepingMode): boolean {
  const externalSystemTypeBookkeepingModeFeature: number = externalSystemType.features & ExternalSystemTypeBookkeepingModeFeature;

  return !(externalSystemTypeBookkeepingModeFeature & ~bookkeepingMode);
};

export function isExternalSystemTypeActive(externalSystemType: ExternalSystemType): boolean {
  return externalSystemType.status === ExternalSystemTypeStatus.ACTIVE;
}

export enum AccountingSystem {
  UNIMICRO = 'Uni Economy Live',
  DNB_REGNSKAP = 'DNB Regnskap Live',
  SPARE_BANK_1_REGNSKAP = 'SpareBank 1 Regnskap Live',
  EIKA_REGNSKAP = 'Eika Regnskap',
  TEST_UNIMICRO = 'SoftRig Test',
}

export const ACCOUNTING_SYSTEMS: AccountingSystem[] = [
  AccountingSystem.UNIMICRO,
  AccountingSystem.DNB_REGNSKAP,
  AccountingSystem.SPARE_BANK_1_REGNSKAP,
  AccountingSystem.EIKA_REGNSKAP,
  AccountingSystem.TEST_UNIMICRO,
];

export enum AccountingSystemAlias {
  UNIMICRO = 'Unimicro',
  TEST_UNIMICRO = 'Test Unimicro',
}

export const ACCOUNTING_SYSTEM_ALIASES: AccountingSystemAlias[] = [
  AccountingSystemAlias.UNIMICRO,
  AccountingSystemAlias.TEST_UNIMICRO,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSupportedAccountingSystem(accountingSystem: any): accountingSystem is AccountingSystem {
  return ACCOUNTING_SYSTEMS.includes(accountingSystem);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSupportedAccountingSystemAlias(accountingSystemAlias: any): accountingSystemAlias is AccountingSystemAlias {
  return ACCOUNTING_SYSTEM_ALIASES.includes(accountingSystemAlias);
}

export const ACCOUNTING_SYSTEM_ALIAS_TO_ACCOUNTING_SYSTEM_MAP: Record<AccountingSystemAlias, AccountingSystem> = {
  [AccountingSystemAlias.UNIMICRO]: AccountingSystem.UNIMICRO,
  [AccountingSystemAlias.TEST_UNIMICRO]: AccountingSystem.TEST_UNIMICRO,
};

export enum Feed {
  GOOGLE_PRODUCT_FEED = 'GoogleProductFeed',
  TELARIS_PRODUCT_FEED = 'TelarisProductFeed',
}

export enum SalesChannel {
  GENERIC = 'Generic',
  SHOPIFY = 'Shopify',
  UNIMICRO_NETTBUTIKK = 'UniNettbutikk',
  MOOCOMMERCE = 'MooCommerce',
}

export const SALES_CHANNELS: SalesChannel[] = [
  SalesChannel.GENERIC,
  SalesChannel.SHOPIFY,
  SalesChannel.UNIMICRO_NETTBUTIKK,
  SalesChannel.MOOCOMMERCE,
];

export enum SalesChannelAlias {
  UNIMICRO_NETTBUTIKK = 'Unimicro Nettbutikk',
  WOOCOMMERCE = 'WooCommerce',
}

export const SALES_CHANNEL_ALIASES: SalesChannelAlias[] = [
  SalesChannelAlias.UNIMICRO_NETTBUTIKK,
  SalesChannelAlias.WOOCOMMERCE,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSupportedSalesChannel(salesChannel: any): salesChannel is SalesChannel {
  return SALES_CHANNELS.includes(salesChannel);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSupportedSalesChannelAlias(salesChannelAlias: any): salesChannelAlias is SalesChannelAlias {
  return SALES_CHANNEL_ALIASES.includes(salesChannelAlias);
}

export const SALES_CHANNEL_ALIAS_TO_SALES_CHANNEL_MAP: Record<SalesChannelAlias, SalesChannel> = {
  [SalesChannelAlias.UNIMICRO_NETTBUTIKK]: SalesChannel.UNIMICRO_NETTBUTIKK,
  [SalesChannelAlias.WOOCOMMERCE]: SalesChannel.MOOCOMMERCE,
};

export enum InventoryManagementSystem {
  AVARE = 'Avare',
}

export enum PointOfSale {
  VIPPSNUMMER = 'Vippsnummer',
  ZETTLE = 'Zettle',
}

export const POINT_OF_SALES: PointOfSale[] = [
  PointOfSale.VIPPSNUMMER,
  PointOfSale.ZETTLE,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSupportedPointOfSale(pointOfSale: any): pointOfSale is PointOfSale {
  return POINT_OF_SALES.includes(pointOfSale);
}

export enum PaymentProvider {
  VIPPS = 'Vipps',
  KLARNA = 'Klarna',
  STRIPE = 'Stripe',
  NETS = 'Nets',
  PAYPAL = 'Paypal',
  CLEARHAUS = 'Clearhaus',
  SVEA = 'Svea',
}

export const PAYMENT_PROVIDERS: PaymentProvider[] = [
  PaymentProvider.VIPPS,
  PaymentProvider.KLARNA,
  PaymentProvider.STRIPE,
  PaymentProvider.NETS,
  PaymentProvider.PAYPAL,
  PaymentProvider.CLEARHAUS,
  PaymentProvider.SVEA,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSupportedPaymentProvider(paymentProvider: any): paymentProvider is PaymentProvider {
  return PAYMENT_PROVIDERS.includes(paymentProvider);
}

export function isAccountingSystem(externalSystemTypes: ExternalSystemType[], accountingSystem: ID): accountingSystem is AccountingSystem {
  return externalSystemTypes.some((externalSystemType: ExternalSystemType) => externalSystemType.id === accountingSystem && externalSystemType.typeKindId === ExternalSystemKind.ACCOUNTING_SYSTEM);
}

export function isFeed(externalSystemTypes: ExternalSystemType[], feed: ID): feed is Feed {
  return externalSystemTypes.some((externalSystemType: ExternalSystemType) => externalSystemType.id === feed && externalSystemType.typeKindId === ExternalSystemKind.FEED);
}

export function isSalesChannel(externalSystemTypes: ExternalSystemType[], salesChannel: ID): salesChannel is SalesChannel {
  return externalSystemTypes.some((externalSystemType: ExternalSystemType) => externalSystemType.id === salesChannel && externalSystemType.typeKindId === ExternalSystemKind.SALES_CHANNEL);
}

export function isInventoryManagementSystem(externalSystemTypes: ExternalSystemType[], ims: ID): ims is InventoryManagementSystem {
  return externalSystemTypes.some((externalSystemType: ExternalSystemType) => externalSystemType.id === ims && externalSystemType.typeKindId === ExternalSystemKind.INVENTORY_MANAGEMENT_SYSTEM);
}

export function isPointOfSale(externalSystemTypes: ExternalSystemType[], pointOfSale: ID): pointOfSale is PointOfSale {
  return externalSystemTypes.some((externalSystemType: ExternalSystemType) => externalSystemType.id === pointOfSale && externalSystemType.typeKindId === ExternalSystemKind.POINT_OF_SALE);
}

export function isPaymentProvider(externalSystemTypes: ExternalSystemType[], paymentProvider: ID): paymentProvider is PaymentProvider {
  return externalSystemTypes.some((externalSystemType: ExternalSystemType) => externalSystemType.id === paymentProvider && externalSystemType.typeKindId === ExternalSystemKind.PAYMENT_PROVIDER);
}

export function isAccountingSystemConnectionPredicate(externalSystemTypes: ExternalSystemType[]): (companyConnection: CompanyConnection) => boolean {
  return (companyConnection: CompanyConnection): boolean => {
    return isAccountingSystem(externalSystemTypes, companyConnection.externalSystemTypeId);
  }
}
